import { object, string, mixed } from 'yup'
const { emailRule, passwordRule, passwordRepeatRule } = validationRules()

const updateAccountFormSchema = object({
  username: string().required().max(16),
  email: emailRule,
})

const deactivateAccountFormSchema = object({
  reason: mixed().test(
    'selected',
    'Please select an option',
    (value) => (value as { label: string; code: number }).code > 0,
  ),
  message: string().when('reason.code', {
    is: 6,
    then: (schema) => schema.required().min(20),
    otherwise: (schema) => schema.notRequired(),
  }),
})

const updatePasswordFormSchema = object({
  currentPassword: string().required(),
  newPassword: passwordRule,
  confirmPassword: passwordRepeatRule,
})

export {
  deactivateAccountFormSchema,
  updateAccountFormSchema,
  updatePasswordFormSchema,
}

import { useForm } from 'vee-validate'
import {
  updateAccountFormSchema,
  deactivateAccountFormSchema,
  updatePasswordFormSchema,
} from 'account/validation-schema/settings'
interface UpdateAccountFormData {
  profilePicture?: string | File
  username: string
  email: string
}

export const useSettingsStore = defineStore('accountSettingsStore', () => {
  const customerStore = useCustomerStore()
  const creatorStore = useCreatorStore()
  const { $notify } = useNuxtApp()

  // UPDATE ACCOUNT FORM
  const getUpdateAccountFormData = (): UpdateAccountFormData => {
    return {
      profilePicture: customerStore.profilePicture,
      username: customerStore.customer?.middleName as string,
      email: customerStore.customer?.email as string,
    }
  }
  const updateAccountForm = useForm({
    validationSchema: updateAccountFormSchema,
    initialValues: getUpdateAccountFormData(),
  })
  const [profilePicture] = updateAccountForm.useFieldModel(['profilePicture'])
  const [usernameField] = updateAccountForm.useFieldModel(['username'])
  const [emailField] = updateAccountForm.useFieldModel(['email'])

  const { isSubmitted: isUpdateAccountFormSubmitted } = useValidation(
    updateAccountForm.submitCount,
  )
  // DEACTIVATE ACCOUNT FORM
  const deactivateAccountForm = useForm({
    validationSchema: deactivateAccountFormSchema,
    initialValues: {
      reason: {
        label: '',
        code: 0,
      },
      message: '',
    },
  })
  const [reasonField] = deactivateAccountForm.useFieldModel(['reason'])
  const [messageField] = deactivateAccountForm.useFieldModel(['message'])

  const { isSubmitted: isDeactivateAccountFormSubmitted } = useValidation(
    deactivateAccountForm.submitCount,
  )
  // UPDATE PASSWORD FORM
  const updatePasswordForm = useForm({
    validationSchema: updatePasswordFormSchema,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })
  const [currentPasswordField, newPasswordField, confirmPasswordField] =
    updatePasswordForm.useFieldModel([
      'currentPassword',
      'newPassword',
      'confirmPassword',
    ])
  const { isSubmitted: isUpdatePasswordFormSubmitted } = useValidation(
    updatePasswordForm.submitCount,
  )
  // Actions
  const resetForms = () => {
    updateAccountForm.resetForm({
      values: getUpdateAccountFormData(),
    })
    deactivateAccountForm.resetForm()
    updatePasswordForm.resetForm()
  }
  const updateAccountAndSetForm = updateAccountForm.handleSubmit(
    async ({ email, username }) => {
      const statuses = {
        'personal info': false,
        'profile image': false,
      }
      statuses['personal info'] = await customerStore.updateAccount(
        [
          {
            action: 'setMiddleName',
            middleName: username,
          },
          ...(email !== customerStore.email
            ? [
                {
                  action: 'changeEmail',
                  email,
                },
              ]
            : []),
        ],
        { notify: false, prefetchData: true },
      )
      statuses['profile image'] = await customerStore.updateProfilePicture(
        profilePicture.value,
      )

      if (Object.values(statuses).every((el) => el)) {
        if (customerStore.isCreator && creatorStore.profileId) {
          await creatorStore.updateProfile(
            [
              {
                action: 'changeName',
                staged: false,
                name: {
                  'en-US': customerStore.customer?.middleName as string,
                },
              },
            ],
            { prefetchData: true },
          )
        }
        $notify('Updated account successfully')
      } else {
        Object.entries(statuses).forEach(([source, value]) => {
          if (value) {
            $notify(`Updated ${source} successfully`)
          } else {
            $notify({
              text: `Failed to update ${source}`,
              severity: 'error',
            })
          }
        })
      }
    },
  )
  const deactivateAccountOnSubmit = deactivateAccountForm.handleSubmit(
    async ({ reason, message }) => {
      await customerStore.deactivateAccount(reason, message)
    },
  )
  const updatePasswordOnSubmit = updatePasswordForm.handleSubmit(
    async ({ currentPassword, newPassword }) => {
      await customerStore.updatePassword(currentPassword, newPassword)
    },
  )

  return {
    confirmPasswordField,
    currentPasswordField,
    deactivateAccountForm,
    deactivateAccountOnSubmit,
    profilePicture,
    emailField,
    isDeactivateAccountFormSubmitted,
    isUpdateAccountFormSubmitted,
    isUpdatePasswordFormSubmitted,
    messageField,
    newPasswordField,
    reasonField,
    updateAccountAndSetForm,
    updateAccountForm,
    updatePasswordOnSubmit,
    updatePasswordForm,
    usernameField,
    resetForms,
  }
})
